import { Dashboard, ArrowForward, Settings } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RoleEnum } from "../../enums/role.enum";
import { useStore } from "../../hooks/store.hook";
import AuthorizationGuardComponent from "../authorization-guard/authorization-guard.component";
import NavbarItemComponent from "../navbar-item/navbar-item.component";
import SectionTitleComponent from "../section-title/section-title.component";
import SideNavigationComponentStyled from "./side-navigation.component.styled";
import { DialogResponseTypeEnum } from "../../enums/dialog-response-type.enum";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { IconButton, Tooltip } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CategoryIcon from '@mui/icons-material/Category';
import CakeIcon from '@mui/icons-material/Cake';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GroupIcon from '@mui/icons-material/Group';
import KitchenIcon from '@mui/icons-material/Kitchen';
import PendingIcon from '@mui/icons-material/Pending';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';

export type NavigationItem = {
    to?: string;
    icon?: React.ReactNode;
    name: string;
    roles?: RoleEnum[];
    subitems: NavigationItem[];
    onItemClick?: () => void;
    determineIfFocused?: () => boolean;
}

export type NavigationGroup = {
    name: string;
    roles?: RoleEnum[];
    items: NavigationItem[];
}

export type NavigationTree = {
    groups: NavigationGroup[];
}

const SideNavigationComponent = observer(() => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');
    const globalStore = useStore('globalStore');

    const { pathname } = useLocation();

    /** inject navigator to navigate to other routes */
    const navigate = useNavigate();

    const navTree: NavigationTree = useMemo(
        (): NavigationTree => ({
            groups: [
                {
                    name: '',
                    items: [
                        {
                            name: 'Dashboard',
                            to: '/app/dashboard',
                            icon: <Dashboard />,
                            subitems: []
                        }
                    ]
                },
                {
                    name: 'General',
                    items: [
                        {
                            name: 'Categorii',
                            icon: <CategoryIcon />,
                            to: '/app/categorii',
                            subitems: []
                        },
                        {
                            name: 'Ingrediente',
                            icon: <KitchenIcon />,
                            to: '/app/ingrediente',
                            subitems: []
                        },
                        {
                            name: 'Produse',
                            icon: <CakeIcon />,
                            to: '/app/produse',
                            subitems: []
                        },
                        {
                            name: 'Rute de livrare',
                            icon: <LocalShippingIcon />,
                            to: '/app/rute-de-livrare',
                            subitems: []
                        },
                        // {
                        //     name: 'Creeaza o comanda',
                        //     subitems: [],
                        //     icon: <AddShoppingCart />,
                        //     onItemClick: () => {
                        //         uiStore.openPanel({
                        //             key: PanelType.AddOrder,
                        //             component: <OrderPanelComponent />,
                        //             title: 'Creeaza o comanda',
                        //             panelWidth: '700px'
                        //         });
                        //     },
                        //     determineIfFocused: () => {
                        //         return uiStore.openedPanels.some(s => s.key === PanelType.AddOrder);
                        //     }
                        // }
                    ]
                },
                {
                    name: 'Parteneri',
                    items: [
                        {
                            name: 'Parteneri',
                            icon: <GroupIcon />,
                            to: '/app/partneri',
                            subitems: []
                        }
                    ]
                },
                {
                    name: 'Comenzi',
                    items: [
                        {
                            name: 'Comenzi',
                            subitems: [],
                            to: '/app/comenzi',
                            icon: <ReceiptLongIcon />
                        }
                    ],
                    roles: [RoleEnum.Production, RoleEnum.Admin]
                },
                {
                    name: 'Utilizatori interni',
                    items: [
                        {
                            name: 'Utilizatori interni',
                            subitems: [],
                            to: '/app/utilizatori-interni',
                            icon: <AccountCircleIcon />
                        }
                    ]
                },
                {
                    name: "Zona producție",
                    items: [
                        {
                            name: 'Zona producție',
                            subitems: [],
                            to: '/app/zona-productie',
                            icon: <PendingIcon />
                        },
                    ],
                    roles: [RoleEnum.Production, RoleEnum.Admin]
                },
                {
                    name: "Pregătire comenzi livrare",
                    items: [
                        {
                            name: 'Pregătire comenzi livrare',
                            subitems: [],
                            to: '/app/zona-livrare',
                            icon: <DeliveryDiningIcon />
                        },
                    ],
                    roles: [RoleEnum.Order, RoleEnum.Admin]
                },
                {
                    name: "Observații zilnice - Rute de livrare",
                    items: [
                        {
                            name: 'Observații zilnice',
                            subitems: [],
                            to: '/app/observatii-zilnice-rute',
                            icon: <ErrorOutlineIcon />
                        },
                    ],
                    roles: [RoleEnum.Admin]
                },
                {
                    name: "Loguri",
                    items: [
                        {
                            name: 'Loguri',
                            subitems: [],
                            to: '/app/logs',
                            icon: <QueuePlayNextIcon />
                        },
                    ],
                    roles: [RoleEnum.Admin]
                },
            ]
        }),
        [uiStore]
    )

    const settingsItem = useMemo(
        (): NavigationItem => {
            return {
                name: 'Setari',
                subitems: [],
                to: '/app/settings',
                icon: <Settings />
            }
        },
        []
    )

    const onNavItemClick = useCallback(
        async (item: NavigationItem) => {
            if(tableStore.updatedData?.length){
                const response = await uiStore.openDialog({
                    title: "Ai date nesalvate!",
                    message: "Vrei sa parasesti pagina?",
                })    
                if(response.value === DialogResponseTypeEnum.Confirm) {
                    uiStore.dismissPersistentToast()
                    tableStore.resetOldUpdatedData()
                    if (item.to) {
                        if (pathname === item.to) return;
                        navigate(item.to);
                    } else if (item.onItemClick) {
                        item.onItemClick();
                    }
                }
            }
            else {
                if (item.to) {
                    if (pathname === item.to) return;
                    navigate(item.to);
                } else if (item.onItemClick) {
                    item.onItemClick();
                }
            }
        },
        [pathname, navigate, uiStore, tableStore]
    )

    const determineIfItemIsActive = useCallback(
        (item: NavigationItem): boolean => {
            if (item.to)
                return pathname.indexOf(item.to) >= 0;
            else
                return item.determineIfFocused ? item.determineIfFocused() : false;
        },
        [pathname]
    )

    const toggleNavbarState = useCallback(
        () => {
            uiStore.toggleNavbarState();
        },
        [uiStore]
    )


    /** define the return statement bellow */
    return (
        <SideNavigationComponentStyled isExpanded={uiStore.sideNavbarExtended}>
            {
                    uiStore.sideNavbarExtended ?
                    <div className="app-title">Elip Optim Admin</div> :
                    null
            }

            <div className="toggle-navbar-button">
                <IconButton aria-label="go-back" onClick={toggleNavbarState}>
                    <ArrowForward />
                </IconButton>
            </div>

            <div className="inner-content">
                <div className="navbar-tree-inner">
                    
                    <div>
                    {
                        navTree.groups.map(group => {
                            return (
                                <AuthorizationGuardComponent roles={group.roles} key={group.name}>
                                    <div className="navbar-group">
                                        <div className={`navbar-group-name ${uiStore.sideNavbarExtended ? 'show-group-name' : 'hide-group-name'}`}>
                                            <SectionTitleComponent>
                                                {group.name}
                                            </SectionTitleComponent>
                                        </div>
                                        <div className="navbar-group-items">
                                            {
                                                group.items.map((item, index) => {
                                                    return (
                                                        <AuthorizationGuardComponent roles={item.roles} key={index}>
                                                            <div className="navbar-group-item">
                                                                <div className="navbar-group-item-header">
                                                                    <NavbarItemComponent
                                                                        item={item}
                                                                        isActive={determineIfItemIsActive(item)}
                                                                        onClick={onNavItemClick}
                                                                        canDisplayName={uiStore.sideNavbarExtended}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AuthorizationGuardComponent>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </AuthorizationGuardComponent>

                            )
                        })
                    }
                    {
                        uiStore.sideNavbarExtended ?
                        <div 
                            style={{
                                border: "1px solid black",
                                padding: "1rem",
                                borderRadius: "8px"
                            }}
                            className="width-license"
                        >
                            <div className="text-style">Licență:</div>
                            <div>
                                {globalStore.license?.license}
                            </div>
                        </div>
                        :
                        null
                    }
                    </div>

                    {/* <div className="settings-item">
                        <div className="navbar-group-items">
                            <div className="navbar-group-item">
                                <div className="navbar-group-item-header">
                                
                                </div>
                            </div>
                        </div>
                    </div> */}
                    
                </div>

            </div>
        </SideNavigationComponentStyled>
    )

})

export default SideNavigationComponent;