export type ThemeVariablesType = {
    colors: {
        primary: string;
        primaryDarker: string;
        primaryLighter: string;
        backgroundGray: string;
        backgroundGrayDarker: string;
        backgroundPrimary: string;
        backgroundPrimaryRGB: string;
        primaryLight: string;
        lightGray: string;
        success: string;
        error: string;
    },
    textColors: {
        primary: string;
        gray: string;
    }
    fontSizes: {
        normal: string;
        medium: string;
        large: string;
        xLarge: string;
        xxLarge: string;
        smaller: string;
        subtitle: string;
        xSmall: string;
        xxSmall: string;
    },
    fontWeights: {
        thin: number;
        extraLight: number;
        light: number;
        regular: number;
        medium: number;
        bold: number;
        semibold: number;
        extraBold: number;
        black: number;
    },
    semanticColors: {
        success: string;
        successBackground: string;
        error: string;
        errorBackground: string;
        warning: string;
        informative: string;
    }
}

export const themeVariables: ThemeVariablesType = {
    colors: {
        primary: '#5C8DF6',
        primaryDarker: '#1E3A8C',
        backgroundGray: '#F2F4F8',
        backgroundGrayDarker: '#BCC4D4',
        primaryLighter: '#A8C4F6',
        backgroundPrimary: '#FDFDFD',
        backgroundPrimaryRGB: '253, 253, 253',
        primaryLight: '#7BA6F6',
        lightGray: '#E8ECF2',
        success: "#68B96A",
        error: "#E74C3C"
    },
    textColors: {
        gray: 'gray',
        primary: '#263238'
    },
    fontSizes: {
        normal: '1rem',
        medium: '1.25rem',
        large: '1.5rem',
        xLarge: '1.75rem',
        xxLarge: '2rem',
        smaller: '0.8rem',
        subtitle: '0.9rem',
        xSmall: '0.75rem',
        xxSmall: '0.6rem',
    },
    fontWeights: {
        thin: 100,
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extraBold: 800,
        black: 900
    },
    semanticColors: {
        success : '#00ab55',
        error: '#cb5765',
        warning: '#ffc608',
        informative: '#4d87f3',
        successBackground: '#c8facd',
        errorBackground: '#f6c9cb'
    }
}

export const fontSizes = {
    fontSize1: "0.75rem", // 12 px
    fontSize2: "0.875rem", // 14 px
    fontSize3: "1rem", // 16 px
    fontSize4: "1.125rem", // 18 px
    fontSize5: "1.25rem", // 20 px
    fontSize6: "1.375rem" // 22 px
}

export const fontColors = {
    colorPrimary: "#01a0fe",
    colorMain: "#333333"
}

export const fontWeights = {
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightEegular: 400,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightBlack: 900
}